<template>
  <div class="card mb-5 mb-xl-10" v-if="profileDetails">
    <div class="card-header border-0 cursor-pointer">
      <div class="card-title m-0">
        <h3 class="fw-bolder m-0">Profile Details</h3>
      </div>
    </div>

    <div id="student_profile" class="collapse show">
      <el-form
        id="student_profile_form"
        class="form"
        :model="profileDetails"
        ref="formRef"
        :rule="rules"
      >
        <div class="card-body border-top p-9">
          <div class="row mb-6">
            <label class="col-lg-4 col-form-label fw-bold fs-6">Picture</label>

            <div class="col-lg-8">
              <div
                class="image-input image-input-outline"
                data-kt-image-input="true"
                style="background-image: url(/media/avatars/blank.png)"
              >
                <div
                  class="image-input-wrapper w-125px h-125px"
                  :style="`background-image: url(${ profileDetails.photoUrl != undefined ? profileDetails.photoUrl : '/media/avatars/blank.png' })`"
                ></div>

                <label
                  class="
                    btn btn-icon btn-circle btn-active-color-primary
                    w-25px
                    h-25px
                    bg-white
                    shadow
                  "
                  data-kt-image-input-action="change"
                  data-bs-toggle="tooltip"
                  title="Change photo"
                >
                  <i class="bi bi-pencil-fill fs-7"></i>
                  <input type="file" name="photo" id="studentPhoto" @change="showPreview" accept=".png, .jpg, .jpeg" />
                  <input type="hidden" name="photo_remove" />
                </label>
                
                <span
                  class="
                    btn btn-icon btn-circle btn-active-color-primary
                    w-25px
                    h-25px
                    bg-white
                    shadow
                  "
                  data-kt-image-input-action="remove"
                  data-bs-toggle="tooltip"
                  @click="removeImage()"
                  title="Remove picture"
                >
                  <i class="bi bi-x fs-2"></i>
                </span>
              </div>

              <div class="form-text">Allowed file types: png, jpg, jpeg.</div>
            </div>
          </div>

          <div class="row mb-6">
            <label class="col-lg-4 col-form-label required fw-bold fs-6">
              Name
            </label>

            <div class="col-lg-8">
              <div class="row">
                <div class="col-lg-6 fv-row">
                  <el-form-item prop="first_name">
                  <input
                    type="text"
                    name="fname"
                    class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder="First name"
                    v-model="profileDetails.first_name"
                  />
                  </el-form-item>
                </div>

                <div class="col-lg-6 fv-row">
                  <el-form-item prop="last_name">
                  <input
                    type="text"
                    name="lname"
                    class="form-control form-control-lg form-control-solid"
                    placeholder="Last name"
                    v-model="profileDetails.last_name"
                  />
                  </el-form-item>
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-6">
            <label class="col-lg-4 col-form-label fw-bold fs-6"
              >Student Id</label
            >

            <div class="col-lg-8 fv-row">
              <el-form-item prop="student_id">
              <input
                type="text"
                name="student_id"
                class="form-control form-control-lg form-control-solid"
                placeholder="Student Id : if this field is empty, it will be automatically updated."
                v-model="profileDetails.student_id"
              />
              </el-form-item>
            </div>
          </div>

          <div class="row mb-6">
            <label class="col-lg-4 col-form-label fw-bold fs-6">
              Sevis No
            </label>

            <div class="col-lg-8 fv-row">
              <el-form-item prop="student_id">
              <input
                type="text"
                name="sevis_no"
                class="form-control form-control-lg form-control-solid"
                placeholder="Sevis No"
                v-model="profileDetails.sevis_no"
              />
              </el-form-item>
            </div>
          </div>

          <div class="row mb-6">
            <label class="col-lg-4 col-form-label fw-bold fs-6">
              Contact Phone
            </label>

            <div class="col-lg-8 fv-row">
              <el-form-item prop="phone">
              <input
                type="text"
                name="phone"
                class="form-control form-control-lg form-control-solid"
                placeholder="Phone number"
                v-model="profileDetails.phone"
                @input="acceptPhoneNumber"
              />
              </el-form-item>
            </div>
          </div>
          
          <div class="row mb-6">
            <label class="col-lg-4 col-form-label fw-bold fs-6">
              Email
            </label>

            <div class="col-lg-8 fv-row">
              <el-form-item prop="email">
              <input
                type="text"
                name="email"
                class="form-control form-control-lg form-control-solid"
                placeholder="email"
                v-model="profileDetails.email"
              />
              </el-form-item>
            </div>
          </div>

          <div class="row mb-6">
            <label class="col-lg-4 col-form-label fw-bold fs-6">
              Date of birth
            </label>

            <div class="col-lg-8 fv-row">
              <el-form-item prop="birth_date">
                <el-date-picker
                    v-model="profileDetails.birth_date"
                    type="date"
                    start-placeholder="Date of birth"
                    format="MM/DD/YYYY"                
                />
              </el-form-item>
            </div>
          </div>

          <div class="row mb-6">
            <label class="col-lg-4 col-form-label fw-bold fs-6">
              Address
            </label>

            <div class="col-lg-8 fv-row">
                <div class="row">
                    <div class="col-lg-12 fv-row">
                      <el-form-item prop="address1">
                      <input
                        type="text"
                        name="address1"
                        class="form-control form-control-lg form-control-solid"
                        placeholder="Address1"
                        v-model="profileDetails.address.address1"


                      />
                      </el-form-item>
                    </div>
                    <div class="col-lg-12 fv-row">
                      <el-form-item prop="address2">
                      <input
                        type="text"
                        name="address2"
                        class="form-control form-control-lg form-control-solid"
                        placeholder="Address2"
                        style="margin-top:5px;margin-bottom:5px;"
                        v-model="profileDetails.address.address2"
                      />
                      </el-form-item>
                    </div>
                    <div class="col-lg-4 fv-row">
                      <el-form-item prop="city">
                        <input
                          type="text"
                          name="city"
                          class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                          placeholder="City"
                          v-model="profileDetails.address.city"
                        />
                      </el-form-item>
                    </div>
                    <div class="col-lg-4 fv-row">
                      <el-form-item prop="state">
                        <input
                          type="text"
                          name="State"
                          class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                          placeholder="State"
                          v-model="profileDetails.address.state"
                        />
                      </el-form-item>
                    </div>
                    <div class="col-lg-4 fv-row">
                      <el-form-item prop="zipcode">
                        <input
                          type="text"
                          name="zipcode"
                          class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                          placeholder="Zip Code"
                          v-model="profileDetails.address.zipcode"
                        />
                      </el-form-item>
                    </div>
                </div>
            </div>
          </div>

          <div class="row mb-6">
            <label class="col-lg-4 col-form-label fw-bold fs-6">
              Nationality
            </label>

            <div class="col-lg-8 fv-row">
              <el-form-item prop="nationality">
              <select
                name="country"
                class="form-select form-select-solid form-select-lg fw-bold"
                v-model="profileDetails.nationality"
              >
                <option v-for="country in countries" :key="country" :value="country">
                  {{country}}
                </option>
              </select>
              </el-form-item>
            </div>
          </div>

          <div class="row mb-6">
            <label class="col-lg-4 col-form-label fw-bold fs-6">
              Sex
            </label>

            <div class="col-lg-8 fv-row">
              <el-form-item prop="sex">
              <select
                name="sex"
                class="form-select form-select-solid form-select-lg"
                v-model="profileDetails.sex"
              >
                <option v-for="sex in sexTypes" :key="sex" :value="sex">
                  {{sex}}
                </option>
              </select>
              </el-form-item>
            </div>
          </div>

          <div class="row mb-6">
            <label class="col-lg-4 col-form-label fw-bold fs-6">
              i20 period
            </label>

            <div class="col-lg-8 fv-row">
              <div style="display:inline-block">
                <el-form-item prop="i20_start">
                <el-date-picker
                    v-model="profileDetails.i20_start"
                    type="date"
                    start-placeholder="i20 start date"
                    format="MM/DD/YYYY"
                />
                </el-form-item>
              </div> ~
              <div style="display:inline-block">
                <el-form-item prop="i20_end">
                <el-date-picker
                    v-model="profileDetails.i20_end"
                    type="date"
                    start-placeholder="i20 end date"
                    format="MM/DD/YYYY"
                />
                </el-form-item>
              </div>
            </div>
          </div>

          <div class="row mb-6">
            <label class="col-lg-4 col-form-label fw-bold fs-6">
              Start Date
            </label>

            <div class="col-lg-8 fv-row">
              <el-form-item prop="start_date">
                <el-date-picker
                    v-model="profileDetails.start_date"
                    type="date"
                    start-placeholder="Start Date"
                    format="MM/DD/YYYY"
                />
              </el-form-item>
            </div>
          </div>

          <div class="row mb-6">
            <label class="col-lg-4 col-form-label fw-bold fs-6">
              End Date
            </label>

            <div class="col-lg-8 fv-row">
              <el-form-item prop="end_date">
                <el-date-picker
                    v-model="profileDetails.end_date"
                    type="End Date"
                    start-placeholder="Start Date"
                    format="MM/DD/YYYY"
                />
              </el-form-item>
            </div>
          </div>

          <div class="row mb-6">
            <label class="col-lg-4 col-form-label fw-bold fs-6">
              Agent
            </label>

            <div class="col-lg-8 d-flex align-items-center">
              <el-form-item prop="agent">
                <select
                    name="agent"
                    class="form-select form-select-solid form-select-lg fw-bold"
                    v-model="profileDetails.agent_id"
                  >
                    <option value="-1">None</option>
                    <option v-for="agent in agents" :key="agent.id" :value="agent.id">
                      {{agent.name}}
                    </option>
                  </select>
              </el-form-item>
            </div>
          </div>

          <div class="row mb-6">
            <label class="col-lg-4 col-form-label fw-bold fs-6">
              Advisor
            </label>

            <div class="col-lg-8 d-flex align-items-center">
              <el-form-item prop="advisor">
                <select
                    name="advisor"
                    class="form-select form-select-solid form-select-lg fw-bold"
                    v-model="profileDetails.advisor_id"
                  >
                    <option value="-1">None</option>
                    <option v-for="advisor in advisors" :key="advisor.id" :value="advisor.id">
                      {{advisor.first_name}} {{advisor.last_name}}
                    </option>
                  </select>
              </el-form-item>
            </div>
          </div>

          <div class="row mb-6">
            <label class="col-lg-4 col-form-label fw-bold fs-6">
              Admission Category
            </label>

            <div class="col-lg-8 d-flex align-items-center">
              <el-form-item prop="admission_category">
              <select
                  name="category"
                  class="form-select form-select-solid form-select-lg fw-bold"
                  v-model="profileDetails.admission_category"
                >
                  <option v-for="category in admissionCategories" :key="category" :value="category">
                    {{category}}
                  </option>
                </select>
              </el-form-item>
            </div>
          </div>

          <div class="row mb-6">
            <label class="col-lg-4 col-form-label fw-bold fs-6">
              Status
            </label>

            <div class="col-lg-8 d-flex align-items-center">
              <el-form-item prop="status">
                <select
                  name="status"
                  class="form-select form-select-solid form-select-lg fw-bold"
                  v-model="profileDetails.status"
                >
                  <option v-for="status in studentStatuses" :key="status" :value="status">
                    {{status}}
                  </option>
                </select>
              </el-form-item>
            </div>
          </div>
        </div>

        <div class="card-footer d-flex justify-content-end py-6 px-9">
          <button
            type="button"
            class="btn btn-danger mx-2"
            @click="deleteStudent"
          >
            Delete
          </button>

          <button
            type="button"
            id="kt_account_profile_details_submit"
            @click="submit()"
            class="btn btn-primary"
          >
            <span class="indicator-label"> Save Changes </span>
            <span class="indicator-progress">
              Please wait...
              <span
                class="spinner-border spinner-border-sm align-middle ms-2"
              ></span>
            </span>
          </button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, computed, onBeforeMount } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useStore } from "vuex";
import { useRoute, useRouter } from 'vue-router'
import ApiService from "@/core/services/ApiService";
import { ElMessageBox } from "element-plus";
import CommonService from "@/core/services/CommonService";

export default defineComponent({
  name: "student-settings",
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const id : number = +route.params.id; 
    const countries = ref([]);
    const studentStatuses = ref([]);
    const admissionCategories = ref([]);
    const sexTypes = ref([]);
    const formRef = ref<null | HTMLFormElement>(null);
    const agents = ref<any>([]);
    const advisors = ref<any>([]);
    const isPhotoUpdated = ref(false);

    const rules = ref({
      record_type: [
        {
          required: true,
          message: "Please select transaction type",
          trigger: "change",
        },
      ],
    });

    const getTypes = async() => {
      return new Promise((resolve, reject) => { 
        ApiService.setHeader();
        ApiService.get("student/types")
        .then(({ data }) => {
            countries.value = data.countries;
            studentStatuses.value = data.studentStatuses;
            admissionCategories.value = data.admissionCategories;
            sexTypes.value = data.sexTypes;
            resolve(data.data);
        })
        .catch(({ response }) => {
          reject('getTypes student error => '+response.data.errors);
          console.log('getTypes student error => '+response.data.errors);
        });
      });
    }

    const getAgents = async() =>{
      await new Promise((resolve, reject) => {  
        ApiService.setHeader();
        ApiService.get("agent")
              .then(({ data }) => {
                  agents.value = data.data;
                  if(profileDetails.value.agent_id == null){
                    profileDetails.value.agent_id = -1;
                  }

                  resolve(data);
              })
              .catch(({ response }) => {
                console.log('getAgents error => '+response.data.errors);
                reject(response.data.errors);
              });
      });
    }

    const getAdvisors = async() =>{
      await new Promise((resolve, reject) => {  
        ApiService.setHeader();
        ApiService.get("staff")
              .then(({ data }) => {
                  advisors.value = data.data;
                  if(profileDetails.value.advisor_id == null){
                    profileDetails.value.advisor_id = -1;
                  }
                  resolve(data);
              })
              .catch(({ response }) => {
                console.log('getAdvisors error => '+response.data.errors);
                reject(response.data.errors);
              });
      });
    }

    const profileDetails = computed(() => {
        return store.getters.currentStudentProfile.studentProfile;
    });

    const createStudent = async () => {
        isValidStudentId().then((isValid)=>{
            if(isValid){
                generateStudentId().then(() => {
                ApiService.setHeader();
                ApiService.setFormDataHeader();
                let formdata = filterData();
                ApiService.post("student", formdata as any)
                    .then(({data}) => {
                      Swal.fire({
                          text: "New student is created successfully",
                          icon: "success",
                          buttonsStyling: false,
                          confirmButtonText: "Ok, got it!",
                          customClass: {
                            confirmButton: "btn btn-primary",
                          },
                        })
                        .then(() => {
                            window.location.href=`/student/${data.student.id}/profile`;
                        });;
                        
                    })
                    .catch(({ response }) => {
                        console.log('createStudent error => '+response.data.errors);
                        Swal.fire({
                          text: "Failed to create the new student",
                          icon: "error",
                          buttonsStyling: false,
                          confirmButtonText: "Ok, got it!",
                          customClass: {
                            confirmButton: "btn btn-primary",
                          },
                        });
                    });
                });
            }
            else{
                Swal.fire({
                  text: "Student ID is duplicated. please enter the correct student ID",
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "Ok, got it!",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                });
            }
        });
    }

    const generateStudentId = async() =>{
      if(profileDetails.value.status === "Active" && (profileDetails.value.student_id === undefined||profileDetails.value.student_id === null||profileDetails.value.student_id === '')){
          ApiService.setHeader();
          await ApiService.get("student/studentno/new")
                .then(({ data }) => {
                    profileDetails.value.student_id = data.data;
                })
                .catch(({ response }) => {
                  console.log('getTypes student error => '+response.data.errors);
                });
        }
    }

    const isValidStudentId = async()=> {
        return new Promise<boolean>((resolve, reject) => { 
            if(profileDetails.value.student_id){
                ApiService.setHeader();
                ApiService.get("student/"+profileDetails.value.id+"/studentno/"+profileDetails.value.student_id+"/isValid")
                    .then(({ data }) => {
                        resolve(data.isValid);
                    })
                    .catch(({ response }) => {
                      console.log('getTypes student error => '+response.data.errors);
                      reject(false);
                    });
            }
            else{
              resolve(true);
            }
        });
    }

    const filterData = () =>{
      if(profileDetails.value.agent_id == -1){
        profileDetails.value.agent_id = null;
        profileDetails.value.agent = null;
      }

      if(profileDetails.value.advisor_id == -1){
        profileDetails.value.advisor_id = null;
        profileDetails.value.advisor = null;
      }

      var formData = new FormData();

      if(profileDetails.value.photoFile){
        formData.append('file', profileDetails.value.photoFile);
      }

      delete profileDetails.value.photoFile;
      delete profileDetails.value.photoUrl;
      delete profileDetails.value.agent;
      formData.append("document", JSON.stringify(profileDetails.value));
      
      return formData;
    }

    const showPreview = (event) =>{
      isPhotoUpdated.value = true;
      profileDetails.value.photoUrl = URL.createObjectURL(event.target.files[0]);
      profileDetails.value.photoFile = event.target.files[0];
    }

    const updateStudent = async () => {
      isValidStudentId().then((isValid)=>{
        if(isValid){
          generateStudentId().then(() =>{
          ApiService.setHeader();
          ApiService.setFormDataHeader();
          ApiService.update("student", id, (filterData()) as any)
              .then(() => {
                Swal.fire({
                    text: "Updated successfully",
                    icon: "success",
                    buttonsStyling: false,
                    confirmButtonText: "Ok, got it!",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                  }).then(() =>{
                    router.push({ name: "student-list" });
                  });
              })
              .catch(({ response }) => {
                  console.log('updateStudent error => '+response.data.errors);
                  Swal.fire({
                    text: "Failed to create the new student",
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: "Ok, got it!",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                  });
              });
          });
        }
        else{
           Swal.fire({
              text: "Student ID is duplicated. please enter the correct student ID",
               icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
        }
      });
    }

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          if(id == 0){
            createStudent();
          }
          else{
            updateStudent();
          }
        } else {
          Swal.fire({
            text: "Please fill out all required fields",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          return false;
        }
      });
    };

    const removeImage = () => {
      profileDetails.value.photoUrl = undefined;
      profileDetails.value.photo_id = undefined;
    };

    const deleteStudent = () =>{
      ElMessageBox.confirm('Are you sure to delete this student?')
                .then(() => {
                    ApiService.setHeader();
                    ApiService.delete("student/"+id)
                        .then(async () => {
                            router.push({name:"student-list"});
                        })
                        .catch(({ response }) => {
                            console.log('handleDelete error => '+response.data.errors);
                        });
                });
    }

    const acceptPhoneNumber = () => {
        profileDetails.value.phone = CommonService.getPhoneNumber(profileDetails.value.phone);
    }

    onBeforeMount(async() =>{
      await getTypes().then(async () => {
        getAgents();
        getAdvisors();
      });
    })

    onMounted(() => {
      setCurrentPageBreadcrumbs("Profile", ["Student"]);
    });

    return {
      submit,
      profileDetails,
      formRef,
      removeImage,
      rules,
      countries,
      studentStatuses,
      admissionCategories,
      sexTypes,
      agents,
      advisors,
      showPreview,
      deleteStudent,
      acceptPhoneNumber
    };
  },
});
</script>
